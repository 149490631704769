var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("a-input", {
          staticClass: "header-item",
          attrs: { allowClear: "", placeholder: "ID", size: "small" },
          on: { pressEnter: _vm.search },
          model: {
            value: _vm.params.recordId,
            callback: function($$v) {
              _vm.$set(_vm.params, "recordId", $$v)
            },
            expression: "params.recordId"
          }
        }),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              "filter-option": _vm.untils.filterOption,
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "用户"
            },
            on: {
              search: _vm.searchUserData,
              change: _vm.handleChangeUserSearchParams
            },
            model: {
              value: _vm.params.userId,
              callback: function($$v) {
                _vm.$set(_vm.params, "userId", $$v)
              },
              expression: "params.userId"
            }
          },
          _vm._l(_vm.userList, function(items) {
            return _c(
              "a-select-option",
              { key: items.userId, attrs: { value: items.userId } },
              [_vm._v(_vm._s(items.userName))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "对版类型"
            },
            model: {
              value: _vm.params.visibleStatus,
              callback: function($$v) {
                _vm.$set(_vm.params, "visibleStatus", $$v)
              },
              expression: "params.visibleStatus"
            }
          },
          _vm._l(_vm.visibleStatusTypeList, function(items) {
            return _c(
              "a-select-option",
              { key: items.value, attrs: { value: items.value } },
              [_vm._v(_vm._s(items.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "人工Or机器"
            },
            model: {
              value: _vm.params.manualMachineStatus,
              callback: function($$v) {
                _vm.$set(_vm.params, "manualMachineStatus", $$v)
              },
              expression: "params.manualMachineStatus"
            }
          },
          _vm._l(_vm.manualMachineStatusList, function(items) {
            return _c(
              "a-select-option",
              { key: items.value, attrs: { value: items.value } },
              [_vm._v(_vm._s(items.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "对版来源类型"
            },
            model: {
              value: _vm.params.platformType,
              callback: function($$v) {
                _vm.$set(_vm.params, "platformType", $$v)
              },
              expression: "params.platformType"
            }
          },
          _vm._l(_vm.platformTypeList, function(items) {
            return _c(
              "a-select-option",
              { key: items.value, attrs: { value: items.value } },
              [_vm._v(_vm._s(items.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              dropdownMenuStyle: { "max-height": "300px" },
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "状态"
            },
            model: {
              value: _vm.params.handleStatus,
              callback: function($$v) {
                _vm.$set(_vm.params, "handleStatus", $$v)
              },
              expression: "params.handleStatus"
            }
          },
          _vm._l(_vm.statusList, function(items) {
            return _c(
              "a-select-option",
              { key: items.value, attrs: { value: items.value } },
              [_vm._v(_vm._s(items.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              "filter-option": _vm.untils.filterOption,
              dropdownMenuStyle: { "max-height": "500px" },
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "请选择大币种"
            },
            on: { change: _vm.coinChange, search: _vm.getCoinFilteringList },
            model: {
              value: _vm.params.coinKindId,
              callback: function($$v) {
                _vm.$set(_vm.params, "coinKindId", $$v)
              },
              expression: "params.coinKindId"
            }
          },
          _vm._l(_vm.coinList, function(items) {
            return _c(
              "a-select-option",
              { key: items.coinKindId, attrs: { value: items.coinKindId } },
              [_vm._v(_vm._s(items.coinKindName))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              "filter-option": _vm.untils.filterOption,
              dropdownMenuStyle: { "max-height": "500px" },
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "请选择小币种"
            },
            on: { change: _vm.coinItemChange },
            model: {
              value: _vm.params.coinItemId,
              callback: function($$v) {
                _vm.$set(_vm.params, "coinItemId", $$v)
              },
              expression: "params.coinItemId"
            }
          },
          _vm._l(_vm.coinItemList, function(items) {
            return _c(
              "a-select-option",
              { key: items.id, attrs: { value: items.id } },
              [_vm._v(_vm._s(items.coinKindItemName))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              "filter-option": _vm.untils.filterOption,
              dropdownMenuStyle: { "max-height": "500px" },
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "请选择版别"
            },
            model: {
              value: _vm.params.coinVersionId,
              callback: function($$v) {
                _vm.$set(_vm.params, "coinVersionId", $$v)
              },
              expression: "params.coinVersionId"
            }
          },
          _vm._l(_vm.versionList, function(items) {
            return _c(
              "a-select-option",
              { key: items.id, attrs: { value: items.id } },
              [_vm._v(_vm._s(items.coinKindVersionName))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "推荐类型"
            },
            model: {
              value: _vm.params.sectionType,
              callback: function($$v) {
                _vm.$set(_vm.params, "sectionType", $$v)
              },
              expression: "params.sectionType"
            }
          },
          _vm._l(_vm.sectionTypeList, function(items) {
            return _c(
              "a-select-option",
              { key: items.value, attrs: { value: items.value } },
              [_vm._v(_vm._s(items.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              "filter-option": _vm.untils.filterOption,
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "模型版本",
              dropdownStyle: { "max-height": "1000px" }
            },
            model: {
              value: _vm.params.recognizerVersion,
              callback: function($$v) {
                _vm.$set(_vm.params, "recognizerVersion", $$v)
              },
              expression: "params.recognizerVersion"
            }
          },
          _vm._l(_vm.modelVersionList, function(items) {
            return _c(
              "a-select-option",
              { key: items.value, attrs: { value: items.value } },
              [_vm._v(_vm._s(items.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "标签筛选",
              dropdownMenuStyle: { "max-height": "500px" }
            },
            model: {
              value: _vm.params.markTag,
              callback: function($$v) {
                _vm.$set(_vm.params, "markTag", $$v)
              },
              expression: "params.markTag"
            }
          },
          _vm._l(_vm.markTagList, function(items) {
            return _c(
              "a-select-option",
              { key: items.value, attrs: { value: items.value } },
              [_vm._v(_vm._s(items.name))]
            )
          }),
          1
        ),
        _c(
          "a-checkbox-group",
          {
            on: { change: _vm.handleChangeCheckBox },
            model: {
              value: _vm.isTest,
              callback: function($$v) {
                _vm.isTest = $$v
              },
              expression: "isTest"
            }
          },
          [_c("a-checkbox", { attrs: { value: 1 } }, [_vm._v("测试集刷选")])],
          1
        ),
        false
          ? _c(
              "a-checkbox-group",
              {
                on: { change: _vm.handleChangeCheckBoxByFeedback },
                model: {
                  value: _vm.hasFeedback,
                  callback: function($$v) {
                    _vm.hasFeedback = $$v
                  },
                  expression: "hasFeedback"
                }
              },
              [
                _c("a-checkbox", { attrs: { value: 1 } }, [
                  _vm._v("反馈内容刷选")
                ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "a-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.search }
          },
          [_vm._v("搜索")]
        ),
        _c(
          "a-button",
          {
            staticClass: "header-button",
            attrs: { size: "small", type: "primary", disabled: _vm.isDisabled },
            on: {
              click: function($event) {
                return _vm.batchProcessing()
              }
            }
          },
          [_vm._v("已处理")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "page-body" },
      [
        _c("PageTable", {
          ref: "pageTable",
          attrs: {
            changeTestCheckBox: _vm.changeTestCheckBox,
            list: _vm.list,
            loading: _vm.loading,
            pagination: _vm.pagination
          },
          on: {
            changePage: _vm.changePage,
            success: _vm.handleChangeBigCoin,
            selectChange: _vm.onSelectChange,
            successVersion: function($event) {
              return _vm.handleResetVersionSuccess($event)
            },
            versionSuccess: function($event) {
              return _vm.handleVersionSuccess($event)
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }