import instance from "@/axios"

/** 对版记录，开元通宝背面图收集 */
export const getKaiYuanBackImgCollectApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanKaiyuanSpecialItemData/singleCollect',
        method: 'POST',
        data: params
    })
}

/** 模型对比 */
export const versionModalApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanUserRecord/compareModelVersion',
        method: 'get',
        params: {
            recordId: params
        }
    })
}

/** GPU、CPU对比 */
export const gpuOrCpuVersionApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanUserRecord/modelCompare',
        method: 'get',
        params: {
            recordId: params
        }
    })
}

/** 新版错误收集 */
export const postBadCaseByScanVersionApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/collectLabelVaue',
        method: 'post',
        data: params
    })
}