var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-box" },
    [
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 1000, title: "聊天记录" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            { staticClass: "take-box" },
            [
              _c("a-table", {
                attrs: {
                  pagination: _vm.paginationModal,
                  loading: _vm.loadingModal,
                  columns: _vm.takeColumns,
                  "data-source": _vm.chatList,
                  rowKey: function(record, n) {
                    return n
                  }
                },
                on: { change: _vm.changePageModal },
                scopedSlots: _vm._u([
                  {
                    key: "userNameSlot",
                    fn: function(row) {
                      return _c("div", {}, [
                        _c("div", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(row.userName) + " --【"),
                          _c("span", { staticClass: "color-black" }, [
                            _vm._v(_vm._s(row.userId))
                          ]),
                          _vm._v("】")
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-5 flex-start-center" },
                          [
                            _c(
                              "a-tag",
                              {
                                attrs: {
                                  color:
                                    _vm.twoUserId === row.userId
                                      ? "green"
                                      : "blue"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.twoUserId === row.userId
                                      ? "买家"
                                      : "卖家"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    }
                  },
                  {
                    key: "content",
                    fn: function(row) {
                      return _c("div", {}, [
                        row.msgType === "TIMTextElem"
                          ? _c("div", [_vm._v(_vm._s(row.msg))])
                          : _vm._e(),
                        row.msgType === "TIMImageElem"
                          ? _c("img", {
                              staticStyle: {
                                "max-width": "100px",
                                "max-height": "100px"
                              },
                              attrs: { src: row.msg, alt: "" },
                              on: {
                                click: function($event) {
                                  return _vm.previewImg(row)
                                }
                              }
                            })
                          : _vm._e(),
                        row.msgType === "TIMSoundElem"
                          ? _c("div", { staticClass: "width-100" }, [
                              _c(
                                "audio",
                                { ref: "audioPlayer", attrs: { controls: "" } },
                                [
                                  _c("source", {
                                    attrs: {
                                      src: _vm.audioUrl,
                                      type: "audio/m4a"
                                    }
                                  }),
                                  _vm._v(" 您的浏览器不支持 audio 元素。 ")
                                ]
                              )
                            ])
                          : _vm._e(),
                        row.msgType === "TIMVideoFileElem" && row.msg
                          ? _c("div", [
                              _c(
                                "video",
                                { attrs: { controls: "", width: "200" } },
                                [
                                  _c("source", {
                                    attrs: { src: row.msg, type: "video/mp4" }
                                  })
                                ]
                              )
                            ])
                          : _vm._e(),
                        row.msgType === "TIMVideoFileElem" && !row.msg
                          ? _c("div", [
                              _vm._v(_vm._s("抱歉！当前视频路径未找到"))
                            ])
                          : _vm._e()
                      ])
                    }
                  },
                  {
                    key: "contentTypeSlot",
                    fn: function(row) {
                      return _c("div", {}, [
                        _c("div", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm.contentTypeMapText[row.msgType]))
                        ])
                      ])
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer-btn",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [_c("a-button", { on: { click: _vm.cancel } }, [_vm._v("关闭")])],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }