<template>
  <!-- 推送样本库 -->
  <a-modal v-model="showPopup" :width='500' title="错误收集" @cancel="cancelModal" @ok="confirmModal">
    <a-form-model
        ref="ruleForm"
        :model="params"
        v-bind="layout"
    >
      <a-form-item label="大币种">
        <a-select
            :dropdownMatchSelectWidth="false"
            :filter-option="untils.filterOption"
            :dropdownMenuStyle="{'max-height': '350px'}"
            allowClear
            showSearch
            @search='searchData'
            class="w-200"
            v-model="params.coinId"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="items of coinList"
              :key="items.id"
              :value="items.id"
          >{{ items.coinKindName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="正反面">
        <a-radio-group v-model="params.frontBackSide">
          <a-radio-button :value="1">正面</a-radio-button>
          <a-radio-button :value="2">反面</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="清晰度">
        <a-radio-group v-model="params.clarity">
          <a-radio-button :value="1">正常</a-radio-button>
          <a-radio-button :value="2">模糊</a-radio-button>
          <a-radio-button :value="3">反光</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="清晰度">
        <a-radio-group v-model="params.angle">
          <a-radio-button :value="1">正常</a-radio-button>
          <a-radio-button :value="2">倾斜</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="锈迹">
        <a-radio-group v-model="params.rust">
          <a-radio-button :value="1">无锈</a-radio-button>
          <a-radio-button :value="2">重锈</a-radio-button>
        </a-radio-group>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import untils from "@/untils"
import {postBadCaseByScanVersionApi} from "@/views/cmsPage/versionManage/duibanrecord/_apis"
import {getBigCoinList} from "@/axios/apis"

export default {
  data() {
    return {
      untils,
      showPopup: false,
      coinList: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        recordId: undefined,
        coinId: undefined,
        frontBackSide: undefined,
        clarity: undefined,
        angle: undefined,
        rust: undefined
      }
    };
  },
  methods: {
    /** 展开弹窗 */
    async show(data) {
      this.params.recordId = data.id
      this.params.coinId = data.coinKindId
      await this.searchData(data.coinKindName)
      this.showPopup = true;
    },
    /** 确定按钮 */
    async confirmModal() {
      this.$loading.show()
      const res = await postBadCaseByScanVersionApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.cancelModal()
    },
    /** 关闭 */
    cancelModal() {
      this.showPopup = false;
      this.params = {
        recordId: undefined,
        coinId: undefined,
        frontBackSide: undefined,
        clarity: undefined,
        angle: undefined,
        rust: undefined
      }
    },
    /** 获取大币种列表 */
    async searchData(inner){
      if (!inner) return
      const res = await getBigCoinList(inner, [5])
      if (res.status !== '200') return;
      const {records} = res.data
      this.coinList = records
    },
  }
}
</script>

<style lang="scss" scoped>
</style>