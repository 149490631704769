<template>
  <div class="table-version-pages">
    <a-table
      :columns="columns"
      :data-source="list"
      rowKey="id"
      @change="changePage"
      :pagination="pagination"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <div slot="idSlot" slot-scope="row">
        <div >{{ row.id }}</div>
        <div @click="handleSameCurrencyList(row)" v-if="row.sameId"  class="text-color-red">{{ row.sameId }}</div>
      </div>
      <div
        :class="row.visibleStatus ? '' : 'visibleStatus'"
        slot="coinKindName"
        slot-scope="row"
      >
        <div class="coinKindName-flex">
          <div>
            {{ row.coinKindName + '&nbsp;' +  row.coinPercentage }}
          </div>
          <div v-if="row.tips" style="color: #0f0f0f;width: 100px;">
            ({{ row.tips }})
          </div>
        </div>
        <div>
          <span v-if="[0, 1].includes(row.openStatus)" :style="{'color': row.openStatus === 0 ? 'blue' : 'red'}">（{{ row.openStatus === 0 ? '开放' : '测试' }}）</span>
        </div>
        <div >
          <div style="margin-top: 10px">
            <a-button
                @click="handleShowChangeBigCoinModel(row)"
                size="small"
            >更改</a-button>
          </div>
          <!--     推送    -->
          <div style="margin-top: 10px">
            <a-button
                :disabled="row.coinTrainStatus === 1"
                size="small"
                @click="handlePushMatchingRecords(row)"
            >{{ row.coinTrainStatus === 1 ? '已收集' : '识别错误收集' }}</a-button>
          </div>
          <div style="margin-top: 10px">
            <a-button
                @click="handleExtendQuery(row)"
                size="small"
            >扩展查询</a-button>
          </div>
          <div style="margin-top: 10px">
            <a-popconfirm
                title="确定重新对版吗？"
                ok-text="确认"
                cancel-text="取消"
                @confirm="handleReVersion(row)"
            >
              <a-button
                  @click="(row)"
                  size="small"
              >重新对版</a-button>
            </a-popconfirm>
          </div>
          <a-button
              v-if="false"
              class="mt-10"
              @click="handleShowGpuOrCpuVersion(row)"
              size="small"
          >GPU/CPU消耗对比</a-button>
        </div>
      </div>
      <!-- 用户 -->
      <div
        class="user-name"
        :style="{color: row.attentionStatus === 1 ? 'green' : ''}"
        slot="userName"
        slot-scope="row"
        @click="userSearch(row.userId)"
      >{{row.userName}}</div>

<!--  对版类型    -->
      <div slot="appScanVersionTypeSlot" slot-scope="row">
        <div class="color-blue font-weight-bold" v-if="row.visibleStatus">自动对版</div>
        <div class="color-green font-weight-bold" v-else>自动对版</div>
      </div>

<!--  最终确认版别    -->
      <div slot="confirmVersionNameSlot" slot-scope="row">
        <div>{{ row.confirmVersionName || '-' }}</div>
        <div v-if="row.confirmVersionName">
          <a-button @click="handleResetVersion(row)" type="link">重新修改版别</a-button>
        </div>
      </div>

      <!-- 正面图 -->
      <div
           slot="images"
           slot-scope="row">
        <a-tooltip>
          <template #title>
            <div style="width: 400px;display: flex; justify-content: space-between; align-items: center">
              <img
                  @click="previewImg(row)"
                  :src='row.frontImage'
                  style="width: 100%;height: 100%;"
              />
              <img
                  @click="previewImg(row)"
                  :src='row.backImage'
                  style="width: 100%;height: 100%;"
              />
            </div>
          </template>
          <img
              @click="previewImg(row)"
              style='max-width:50px;max-height:50px;'
              :src='row.frontImage'
          />
        </a-tooltip>
        <div v-if="row.coinKindId === 1">
          <a-button size="small" class="mt-10" @click="handleShowKaiYuanBackImg(row)">开元会昌背面识别</a-button>
        </div>
        <div class="flex-center-center mt-10">
          <a-button
              type="primary"
              size="small"
              @click="handleCutImgUrl(row.frontImage)"
          >正</a-button>
          <a-button
              class="ml-10"
              type="primary"
              size="small"
              @click="handleCutImgUrl(row.backImage)"
          >反</a-button>
        </div>
        <div class="flex-center-center mt-10" v-if="false">
          <a-button
              type="primary"
              size="small"
              @click="handleCutImgUrl(row.frontImage, 'two')"
          >正V2</a-button>
          <a-button
              class="ml-10"
              type="primary"
              size="small"
              @click="handleCutImgUrl(row.backImage, 'two')"
          >反V2</a-button>
        </div>
        <div class="flex-center-center mt-10">
          <a-button
              type="primary"
              size="small"
              @click="handleShowImgMoreInfo(row.frontImage)"
          >类型检测-正</a-button>
          <a-button
              class="ml-10"
              type="primary"
              size="small"
              @click="handleShowImgMoreInfo(row.backImage)"
          >类型检测-反</a-button>
        </div>
        <div class="mt-10">
          <a-button
              size="small"
              type="danger"
              @click="handleCoinBookPutData(row)"
          >图库数据录入</a-button>
        </div>
      </div>
      <div
        slot="version"
        slot-scope="row"
      >
        <div v-if="row.feedbackContent" style="margin-bottom: 10px;color: red">
          {{ '反馈内容：' + row.feedbackContent }}
        </div>
        <ul class="answers-header">
          <li>id</li>
          <li>名称</li>
          <li>等级</li>
          <li>尺寸</li>
          <li>DIS</li>
          <!-- <li>操作</li> -->
        </ul>
        <ul
          class="answers-list"
          v-for="(item, index) in row.versionList"
          :key="index"
        >
          <li :style="{color: item.spTag ? 'red' : ''}">{{item.versionId}}</li>
          <li :style="{color: item.spTag ? 'red' : ''}">{{`${ item.sampleSize ? `【${item.sampleSize}】` : ''}${item.versionName}${item.tagNames ? "（" + item.tagNames + "）"  : ''}`}}</li>
          <li :style="{color: item.spTag ? 'red' : ''}">{{item.versionLevel ? (item.versionLevel) :  '--'}}</li>
          <li :style="{color: item.spTag ? 'red' : ''}">{{item.versionSize ? item.versionSize : '--'}}</li>
          <li :style="{color: item.spTag ? 'red' : ''}">{{item.distance}}</li>
        </ul>
      </div>
      <div
        slot="setup"
        slot-scope="row"
      >
        <a-button
            v-if="row.coinKindId === 1"
            size="small"
            type="primary"
            class="setup-btn"
            @click="handleShowVersionModal(row)"
        >机器对比</a-button>

        <a-button
          size="small"
          type="primary"
          class="setup-btn"
          @click="contrastClick(row, 1, '', 'yuanVersion')"
        >原图对版</a-button>

        <a-button
          size="small"
          type="primary"
          class="setup-btn"
          :disabled="row.confirmStatus == 1"
          @click="confirmSampleStore(row)"
        >确认到样本库</a-button>
<!--    点击推送相关-质检，tui    -->
        <div style="display:flex;">
          <div v-if="(row.qualityTestingStatus != undefined || row.qualityTestingStatus != null) && false">
            <a-popconfirm
                :title=" row.qualityTestingStatus === 2 ? '确定要撤销此项吗？' : '确定要推送此项么？'"
                ok-text="确认"
                cancel-text="取消"
                @confirm="pushMarkClick(row)"
                :disabled="row.qualityTestingStatus === 1"
            >
              <a-button
                  :disabled="row.qualityTestingStatus === 1"
                  v-if="row.qualityTestingStatus === 1"
                  type=""
                  size="small"
                  class="setup-btn"
              > 已质检 </a-button>
              <a-button
                  v-else
                  size="small"
                  :type="row.qualityTestingStatus === 2 ? '' : 'primary'"
                  class="setup-btn"
              > {{ row.qualityTestingStatus === 2 ?  '撤销质检' : '质检'}} </a-button>
            </a-popconfirm>
          </div>
          <a-popconfirm
              title="确定要将此项推送到问题池子吗？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="handlePushProblem(row)"
          >
            <a-button
                size="small"
                type="primary"
                class="setup-btn"
            >推送到问题列表</a-button>
          </a-popconfirm>
        </div>
        <a-popconfirm
          title="确定要删除此项么？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="remove(row.id)"
        >
         <a-button
            size="small"
            type="danger"
          >删除</a-button>
        </a-popconfirm>

        <a-dropdown>
          <a-button
              size="small"
              type="primary"
              style="margin-top: 10px;margin-left: 5px;"
          >其它
<!--          <DownOutlined />-->
          </a-button>

          <!-- <a class="ant-dropdown-link" @click.prevent>
            Hover me
            <DownOutlined />
          </a> -->
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <!-- 审核推荐 -->
                <a-button
                    size="small"
                    type="primary"
                    class="setup-btn"
                    @click="reviewStore(row)"
                >审核</a-button>
              </a-menu-item>
              <a-menu-item>
                <a-button size="small" type="primary" class="setup-btn" @click="falseCoinClick(row, 4)">假币</a-button>
              </a-menu-item>
              <a-menu-item>
                <a-button size="small" type="primary" class="setup-btn" @click="falseCoinClick(row, 6)">无法辨认</a-button>
              </a-menu-item>
              <a-menu-item>
                <a-button size="small" type="primary" class="setup-btn" @click="falseCoinClick(row, 8)">加刀</a-button>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

      </div>

      <!-- 属性 -->
      <div
        slot="propertySlot"
        slot-scope="row"
      >
        <div>{{ row.attribute }}</div>
        
      </div>
    </a-table>
    <!-- 拓片对版 -->
    <ViewPicture ref="showPicture" @versionSuccess="handleVersionSuccess($event)"></ViewPicture>
    <!-- 推送到样本库 -->
    <ModalPushSimStore
      ref="push-m"
      @success="successPushSimStore"
    ></ModalPushSimStore>

     <!-- 审核推荐 -->
    <ModalReviewStore
      ref="push-v"
      @success="success($event)"
    ></ModalReviewStore>
    <!--  人工修改大币种  -->
    <ModalChangeCoinStore
      ref="change-coin"
      @change="changeSuccess"
    />
    <!--  错误收集弹窗  -->
    <ModalErrorCollect
      ref="error-collect"
      @success="handleErrorCollectSuccess($event)"
    />
<!-- 重新修改版别   -->
    <ResetVersion ref="resetVersionEl"  @versionSuccess="handleVersionSuccess($event)"/>
<!-- 开元背图   -->
    <KaiYuanBackImgModelPopup ref="kaiYuanBackImgModelPopupEl"/>
<!-- 模型对比    -->
    <VersionModalPopup ref="versionModalPopupEl"/>
<!-- GPU，CPU小号对比   -->
    <VersionGpuOrCpuPopup ref="versionGpuOrCpuPopupEl"/>
<!--  钱币识别  -->
    <ShowImgScorePopup ref="showImgScorePopupEl" />
<!--  图库录入  -->
    <EditorPopup ref="editorPopupEl"/>
  </div>
</template>

<script>
import { statusValue } from "@/views/cmsPage/versionManage/duibanrecord/data.js";
import { sectionTypeValue } from "@/views/cmsPage/versionManage/duibanrecord/data.js";
import ViewPicture from "@/views/cmsPage/versionManage/duibanrecord/ViewPicture.vue";
import ModalPushSimStore from "@/views/cmsPage/versionManage/duibanrecord/ModalPushSimStore.vue";
import ModalReviewStore from "@/views/cmsPage/versionManage/duibanrecord/ModalReviewStore.vue";
import ModalChangeCoinStore from "@/views/cmsPage/versionManage/duibanrecord/ModalChangeCoinStore";
import ModalErrorCollect from "@/views/cmsPage/versionManage/duibanrecord/ModalErrorCollect";
import {Base64} from "js-base64";
import ResetVersion from "@/views/cmsPage/versionManage/duibanrecord/_component/ResetVersion"
import KaiYuanBackImgModelPopup
  from "@/views/cmsPage/versionManage/duibanrecord/_component/KaiYuanBackImgModelPopup/index.vue"
import {getImgCut} from "@/axios/apis"
import VersionModalPopup from "@/views/cmsPage/versionManage/duibanrecord/_component/VersionModalPopup/index.vue"
import VersionGpuOrCpuPopup from "@/views/cmsPage/versionManage/duibanrecord/_component/VersionGpuOrCpuPopup/index.vue"
import ShowImgScorePopup from "@/components/ShowImgScorePopup/index.vue"
import EditorPopup from "@/views/cmsPage/coinBookManage/natureManage/_components/EditorPopup/index.vue"

export default {
  props: ["list", "loading", "pagination", "changeTestCheckBox"],
  data() {
    return {
      extendQueryList: {},
      selectedRowKeys: [],
      errTextObj: {},
      columns: [
        { title: "Id", align: "center", scopedSlots: {customRender: 'idSlot'} },
        {
          title: "属性",
          align: "center",
          scopedSlots: { customRender: "propertySlot" },
          // customRender: (text, row, index) => {
          //   return manualMachineStatusValue(row.manualMachineStatus || 0);
          // },
        },

        { title: "对版时间", align: "center", dataIndex: "duibanTime" },
        { title: "大币种", align: "center", scopedSlots: {customRender: 'coinKindName'} },
        {
          title: "用户",
          align: "center",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: "正反图",
          align: "center",
          scopedSlots: { customRender: "images" },
        },
        {
          title: "最终确认版别",
          align: "center",
          scopedSlots: { customRender: "confirmVersionNameSlot" },
        },
        {
          title: "版别",
          align: "center",
          scopedSlots: { customRender: "version" },
        },
        {
          title: "推荐类型",
          align: "center",
          customRender: (text, row, index) => {
            return sectionTypeValue(row.markSectionType || 0);
          },
        },
        
        {
          title: "状态",
          align: "center",
          customRender: (text, row, index) => {
            return statusValue(row.handleStatus || 0);
          },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
    };
  },
  computed: {},
  filters: {
    btnDisabled(val) {
      return val == 1 || val == 2;
    },
  },
  watch: {
    loading() {
      this.selectedRowKeys = [];
      this.$emit("selectChange", []);
    },
  },
  components: {
    EditorPopup,
    ShowImgScorePopup,
    VersionGpuOrCpuPopup,
    ViewPicture,
    ModalPushSimStore,
    ModalReviewStore,
    ModalChangeCoinStore,
    ModalErrorCollect,
    ResetVersion,
    KaiYuanBackImgModelPopup,
    VersionModalPopup
  },
  methods: {
    /** 图库数据录入 */
    async handleCoinBookPutData(row) {
      const versionInfo = row.versionList.length ? row.versionList[0] : ''
      const tempData = {
        // id
        id: undefined,
        // 大币种id
        coinId: row.coinKindId,
        // 小币种id
        coinItemId: versionInfo ? versionInfo.itemId : undefined,
        // 版别id
        coinItemVersionId: versionInfo ? versionInfo.versionId : undefined,
        // 性质正面图
        frontImage: row.frontImage,
        // 性质反面图
        backImage: row.backImage,
      }
      await this.$refs.editorPopupEl.show(tempData)
    },
    /** 展示拍品识别 */
    handleShowImgMoreInfo(yuanImg) {
      this.$refs.showImgScorePopupEl.show('', yuanImg)
    },
    /** 模型对比 */
    handleShowVersionModal(row) {
      this.$refs.versionModalPopupEl.showPopup(row)
    },
    /** 抠图测试 */
    async handleCutImgUrl(imgUrl, version) {
      this.$loading.show();
      const res = await getImgCut(imgUrl, version)
      this.$loading.hide();
      if (res.status !== "200") return;
      if (res.data) {
        this.$previewImg({
          current: 0,
          list: [{img_url: res.data.cutoutImage}],
          baseImgField: "img_url",
          showMute: false,
        });
      }
      // this.$refs.cutImgPopupEl.show(res.data.cutoutImage);
    },
    /** 检测开元通宝背图 */
    async handleShowKaiYuanBackImg(row) {
      this.$loading.show()
      const res = await this.axios.post("/dq_admin/duibanKaiyuanSpecialItemData/recoKaiyuanBackModel", {
        backUrl: row.backImage
      })
      this.$loading.hide()
      if (res.status !== '200') return
      await this.$refs.kaiYuanBackImgModelPopupEl.showPopup(res.data, row.backImage)
    },
    /** 推送到底库 */
    handleVersionSuccess(id) {
      this.$emit('versionSuccess', id)
    },
    /** 重新修改版别 */
    handleResetVersion(row) {
      this.$refs.resetVersionEl.show(row)
    },
    /** 确定要推送到问题池子 */
    async handlePushProblem(row) {
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanPushQuestion/pushToDuibanQuestions", {
        params: {
          duibanIds: row.id
        },
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.success("加入成功");
    },
    /** 展示gpu,cpu对版消耗 */
    async handleShowGpuOrCpuVersion(row) {
      await this.$refs.versionGpuOrCpuPopupEl.show(row.id)
    },
    /** 重新对版 */
    async handleReVersion(row) {
      //http://localhost:6666/api/duiban/batchRecalculateUserDuibanRecord?recordIds=306676
      this.$loading.show()
      const res = await this.axios(
          "/dq_admin/duibanTask/batchResetDuibanRecord",
          {params: { recordIds: row.id }}
      )
      this.$loading.hide()
      if (res.status != '200') return
      this.$message.info('成功')
      this.$emit("successVersion", row.id);
    },
    copy_text(text) {
      let oInput = document.createElement("input");
      oInput.value = text;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.remove();
      this.$message.success("已复制进剪切板");
    },
    copy(text) {
      this.copy_text(text);
    },
    /** 加密 */
    encodeData(id) {
      return Base64.encode(id)
    },
    /** 扩展查询按钮 */
    async handleExtendQuery(row) {
      this.$loading.show()
      const res = await this.axios(
          "/dq_admin/duibanUserRecord/extendAverageDuiban",
          {params: { recordId: row.id }}
      )
      this.$loading.hide()
      if (res.status != '200') return
      const findIndex = row.versionList.findIndex(el => el.spTag)
      if (findIndex > 0) {
        row.versionList.splice(findIndex)
      }
      res.data.forEach(el => {
        el.spTag = true
      })
      row.versionList = row.versionList.concat(res.data)
    },
    /** 同款币跳转 */
    handleSameCurrencyList(row) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqmanage/duibanrecord?sameParagraphRecordId=${row.id}`;
      window.open(url)
    },
    /** 展示改变大币种 */
    handleShowChangeBigCoinModel(row) {
      this.$refs['change-coin'].show(row.id)
    },
    // 假币
    async falseCoinClick(row,typeValue) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/duibanUserRecord/counterfeitMoney",
        {
          params: { recordId: row.id , type:typeValue},
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message);
      row.handleStatus = typeValue
      // this.$emit("success");
    },
    userSearch(userId) {
      const origin = location.origin;
      const url = `${origin}/#/cms/dqmanage/duibanrecord?userId=${userId}`;
      window.open(url);
    },
    // 确认到样本库
    async confirmSampleStore(row) {
      this.$refs["push-m"].show(row, this.changeTestCheckBox);
    },
    // 审核推荐
    async reviewStore(row) {
      if (this.errTextObj[row.id]) {
        row.tips = this.errTextObj[row.id]
      }
      this.$refs["push-v"].show(row);
    },
    // 多选
    onSelectChange(keys, arr) {
      this.selectedRowKeys = keys;
      this.$emit("selectChange", arr);
    },
    // 拓片对版
    contrastClick(row, type, versionId, typeVersion) {
      this.$refs["showPicture"].show(row, type, versionId, this.changeTestCheckBox, typeVersion);
    },
    // 推送标注
    async pushMarkClick(row) {
      const { id, qualityTestingStatus } = row;
      this.$loading.show();
      const url = qualityTestingStatus === 2
          ? '/dq_admin/duibanUserRecord/toBeWithdrawnByQualityInspection'
          : '/dq_admin/duibanUserRecord/confirmationData'
      const res = await this.axios(
        url,
        {
          params: { recordId: id + "" },
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.list.forEach(item => {
        if (item.id === id) {
          if (item.qualityTestingStatus === 0) {
            item.qualityTestingStatus = 2
          } else if (item.qualityTestingStatus === 2) {
            item.qualityTestingStatus = 0
          }
        }
      })
    },
    success({errText, id}) {
      this.errTextObj[id] = errText
      this.list.forEach(el => {
        if (el.id === id) {
          el.tips = errText
        }
      })
      // this.$emit("success");
    },
    successPushSimStore({id, coinKindItemVersionName}) {
      this.list.forEach(el => {
        if (el.id === id) {
          el.handleStatus = 10
          el.confirmVersionName = coinKindItemVersionName
        }
      })
      // this.$emit("success");
    },
    changeSuccess() {
      this.$emit("success");
    },
    /** 识别错误收集 */
    async handlePushMatchingRecords(row) {
      this.$refs["error-collect"].show(row)
    },
    /** 错误收集成功 */
    handleErrorCollectSuccess(data) {
      this.list.forEach(el => {
        if (el.id === data.id) {
          el.coinTrainStatus = 1
        }
      })
    },
    async remove(id) {
      this.$loading.show();
      const res = await this.axios("/dq_admin/duibanUserRecord/delRecord", {
        params: { recordId: id },
      });
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success("删除成功");
      this.$emit("success");
    },
    // 切换分页
    changePage(page) {
      this.$emit("changePage", page);
    },
    previewImg(row) {
      const list = [];
      if (row.frontImage) {
        list.push({
          img_url: row.frontImage,
        });
      }
      if (row.backImage) {
        list.push({
          img_url: row.backImage,
        });
      }
      this.$previewImg({
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-color-red {
  margin-top: 10px !important;
  color: #1890ff !important;
  cursor: pointer !important;
  text-decoration: underline;
}
.coinKindName-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100px;
}
.visibleStatus {
  color: green;
}
.user-name {
  color: #1890ff;
  cursor: pointer;
}
.title-content {
  max-width: 600px;
}
.modal {
  input {
    margin-bottom: 5px;
  }
}

.answers-header,
.answers-list {
  display: flex;
  list-style: none;
  li {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
      max-width: 60px;
    }
  }
  .answers-btn {
    color: #40a9ff;
    cursor: pointer;
  }
}
.answers-header {
  background: #ffffff;
}
.setup-btn {
  margin: 5px;
}
</style>