<template>
  <!-- 推送样本库 -->
  <a-modal
    v-model="pushModal"
    :width='800'
    title="确认到样本库"
  >
    <template slot="footer">
      <div style="display: flex;justify-content: flex-end;align-items: center">
        <a-button @click="cancelPushModal">取消</a-button>
        <a-button type="primary" @click="confirmPushModal('sampleToWaiting')">确认到困难样本库</a-button>
        <a-button type="primary" @click="confirmPushModal">确认到样本库</a-button>
      </div>
    </template>
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoin"
          @search="coinSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="curItem.coinKindId"
          placeholder="请选择大币种"
        >
          <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoinItem"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="curItem.coinKindItemId"
          placeholder="请选择小币种"
        >
          <a-select-option
            v-for="item of coinItemList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          @change="changeVersion"
          showSearch
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 280px"
          v-model="curItem.versionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="版别"
        >
          <a-select-option
            v-for="item of versionList"
            :key="item.id"
            :value="item.id"
          >{{ `${item.coinKindVersionName}` }}</a-select-option>
        </a-select>
        <a-checkbox class="checked" v-model:checked="isTest">是否作为测试集</a-checkbox>
      </div>
    </div>

  </a-modal>
</template>

<script>
import untils from "@/untils";
import {getBigCoinList} from "@/axios/apis"
export default {
  data() {
    return {
      untils,
      pushModal: false,
      curItem: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
      isTest: false
    };
  },
  methods: {
    show(data, changeTestCheckBox) {
      this.curItem = JSON.parse(JSON.stringify(data));
      this.isTest = !!changeTestCheckBox
      this.setDefault();
      this.pushModal = true;
    },
    // 默认值
    async setDefault() {
      const res = await getBigCoinList(this.curItem.coinKindName, [5])
      if (res.status !== '200') return;
      const { records } = res.data;
      this.coinList = records;
      let found
      if (this.coinList && this.coinList.length) {
        found = this.coinList.find(el => el.sid === this.curItem.coinKindSid)
      }

      this.curItem.coinKindId = found.id;
      this.curItem.coinSid = found.sid;
      this.curItem.coinKindName = found.coinKindName;
      await this.coinItemSearch();
      const { versionList } = this.curItem;
      //
      if (versionList && versionList[0]) {
        const version = versionList[0];
        const coinItem = this.coinItemList.find((el) => {
          return el.id == version.itemId;
        });
        this.curItem.coinKindItemId = coinItem.id;
        this.versionList = coinItem.coinItemVersionList;
        this.$set(this.curItem, "versionId", version.versionId);
        this.$set(this.curItem, "coinKindItemVersionName", version.versionName);
      }
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    /** 确认到困难样本库 */
    async handleConfirmPushHardModal() {
      this.$message.warn('TODO 接口准备中。。。')
    },
    /** 确认到样本库 */
    async confirmPushModal(type) {
      const { id, coinKindId, coinKindItemId, versionId } = this.curItem;
      this.$loading.show();
      const params = {
        recordId: id,
        coinId: coinKindId,
        itemId: coinKindItemId,
        versionId,
        type: 5,
        isTest: this.isTest ? 1 : 0
      }
      if (type === 'sampleToWaiting') {
        params.operType = 'sampleToWaiting'
      }
      const res = await this.axios(
        "/dq_admin/duibanUserRecord/confirmToEnterTheSampleLibrary",
        {
          params: {...params},
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.isTest = false
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success", {
        id: id,
        coinKindItemVersionName: this.curItem.coinKindItemVersionName.split(' ')[1]
      });
    },

    /** 大币种改变 */
    changeCoin(val) {
      delete this.curItem.coinKindItemId;
      delete this.curItem.coinKindItemName;
      delete this.curItem.versionId;
      delete this.curItem.coinKindItemVersionName;
      if (!val) return;
      const coin = this.coinList.find((el) => {
        return el.id == this.curItem.coinKindId;
      });
      this.curItem.coinSid = coin.sid;
      this.curItem.coinKindName = coin.coinKindName;

      this.coinItemSearch();
    },
    //  选择小币种
    changeCoinItem(val) {
      delete this.curItem.versionId;
      delete this.curItem.coinKindItemVersionName;
      if (!val) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.id == this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      this.versionList = coinItem.coinItemVersionList;
    },
    // 版别改变
    changeVersion(val) {
      if (!val) return;
      const version = this.versionList.find((el) => {
        return el.id == this.curItem.versionId;
      });
      this.curItem.coinKindItemVersionName = version.coinKindVersionName;
    },
    // 删除图片
    deleteImage(index) {
      this.curItem.covers.splice(index, 1);
    },

    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await getBigCoinList(str, [5])
      if (res.status !== '200') return;
      const { records } = res.data;
      this.coinList = records;
    },

    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.curItem.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
  },
};
</script>

<style lang="scss" scoped>
.checked {
  margin-top: 10px;
}
</style>